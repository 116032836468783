<template>
  <div class="p-pegas myPP">
    <div
      class="card-table my-card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block  mobil-st">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="14">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.staffTest") }}
              </div>
              <div class="block-sarche">
                <div class="header__search my__header__search d-flex">
                  <div class="personal__position">
                    <crm-input
                      :size="'small'"
                      :className="'w100'"
                      :inputValue="filterForm.search"
                      v-model="filterForm.search"
                      :class="mode ? 'input__day' : 'input__night'"
                      :icon="'el-icon-search'"
                      class="mr-2"
                    ></crm-input>
                    <select-company
                      :id="filterForm.company_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      v-model="filterForm.company_id"
                    ></select-company>
                  </div>
                  <div class="personal__position">
                    <el-button
                      :size="'small'"
                      type="primary"
                      icon="el-icon-refresh-right"
                      @click="refresh()"
                      >{{ $t("message.refresh") }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="10" class="topGroup">
            <div class="current__item" style="width:163px;">
              <span @click="showFilterDialog = true" class="filter_icon">
                <i class="el-icon-s-operation"></i>
              </span>
              <el-radio-group
                v-model="showSideBar"
                class="icon-naber"
                style="margin-right: 10px"
              >
                <el-radio-button :label="true">
                  <i class="el-icon-s-unfold"></i>
                </el-radio-button>
                <el-radio-button :label="false">
                  <i class="el-icon-s-fold"></i>
                </el-radio-button>
              </el-radio-group>
            </div>
            <div class="current__position mbtop5">
              <div>
                <export-excel
                  v-can="'staffs.excel'"
                  v-if="list.length"
                  :class="
                    mode ? 'button__settingsday' : 'button__settingsnight'
                  "
                  class="btn excel_btn"
                  :data="excel_datas"
                  worksheet="My Worksheet"
                  name="Испытательный Сотрудники.xls"
                  :fields="excel_fields"
                  @fetch="controlExcelData()"
                  style="display: flex;
                  float:none;
                  align-items: center;
                  margin-right: 10px;"
                >
                  <el-button size="mini">
                    <i class="el-icon-document-delete"></i>
                    {{ $t("message.excel") }}
                  </el-button>
                </export-excel>
              </div>
              <crm-create-and-column-settings
                :permission="$options.name"
                :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                @c-create="drawerCreate = true"
                :columns="columns"
                @c-change="updateColumn"
                :v_can_create="'staffs.create'"
              >
              </crm-create-and-column-settings>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div
        class="topGroup"
        :class="mode ? 'tableicon__day' : 'tableicon__night'"
      >
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          class="workers__tabs workers__position__current mobil-tab-click flex1 left_c p-3"
        >
          <el-tab-pane name="first" v-loading="loadingData">
            <span slot="label"><i class="el-icon-menu"></i></span>
            <el-row :gutter="10">
              <el-col
                v-for="(staff, index) in list"
                :key="'index-' + index"
                :xs="12"
                :sm="12"
                :md="8"
                :lg="6"
                :xl="4"
              >
                <div @click="profile(staff)">
                  <el-card
                    shadow="hover"
                    class="mb-5 staff_card"
                    :class="mode ? 'card__day' : 'card__night'"
                    :title="'id: ' + staff.id"
                  >
                    <div v-if="staff.completion_percentage != 0">
                      <div
                        class="completion_percentage"
                        v-if="
                          staff.completion_percentage <= 100 &&
                            staff.completion_percentage >= 75
                        "
                        style="background-color: #12d229"
                      >
                        {{ staff.completion_percentage }}%
                      </div>
                      <div
                        class="completion_percentage"
                        v-else-if="
                          staff.completion_percentage <= 75 &&
                            staff.completion_percentage >= 50
                        "
                        style="background-color: #a1ffac;"
                      >
                        {{ staff.completion_percentage }}%
                      </div>
                      <div
                        class="completion_percentage"
                        v-else-if="
                          staff.completion_percentage <= 50 &&
                            staff.completion_percentage >= 25
                        "
                        style="background-color: #f1c4c4;"
                      >
                        {{ staff.completion_percentage }}%
                      </div>
                      <div
                        class="completion_percentage"
                        v-else-if="
                          staff.completion_percentage <= 25 &&
                            staff.completion_percentage > 0
                        "
                        style="background-color: #b13939b0;"
                      >
                        {{ staff.completion_percentage }}%
                      </div>
                    </div>
                    <span class="icon_leader" v-if="staff.is_leader">
                      <!-- <i class="fa-solid fa-id-badge"></i> -->
                      <i class="fa-solid fa-medal"></i>
                    </span>
                    <span class="icon_manager" v-else-if="staff.subordinate">
                      <i class="fa-solid fa-id-badge"></i>
                    </span>
                    <!-- <font-awesome-icon icon="fa-solid fa-id-badge" /> -->
                    <div class="img-user-worker">
                      <img
                        :src="
                          staff.image
                            ? baseUrl + staff.image.path
                            : '/img/avatar.jpeg'
                        "
                        alt=""
                      />
                    </div>
                    <div class="workers-info text-center">
                      <p
                        class="font-medium pb-1 pt-0  profile__position"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        <strong class="mr-1 font-bold"
                          >{{ $t("message.nameFull") }}:</strong
                        >
                        <span>{{ staff.name + " " + staff.last_name }}</span>
                      </p>
                      <p
                        class="font-medium pb-1 pt-0  profile__position"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        <strong class="mr-1 font-bold"
                          >{{ $t("message.position") }}:</strong
                        >
                        <span>{{
                          staff.position ? staff.position.name : null
                        }}</span>
                      </p>
                      <!-- <p
                        class="font-medium pb-1 pt-0 "
                        v-if="staff.positions.length"
                      >
                        <strong class="mr-1 font-bold"
                          >{{ $t("message.position_2") }}:</strong
                        >
                        <span
                          v-for="(position, positionIndex) in staff.positions"
                          :key="'position' + positionIndex"
                          >{{ position.name }},
                        </span>
                      </p> -->
                      <p
                        class="font-medium pb-1 pt-0  profile__position"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        <strong class="mr-1 font-bold"
                          >{{ $t("message.department") }}:</strong
                        >
                        <span>{{
                          staff.department ? staff.department.name : null
                        }}</span>
                      </p>
                      <p
                        class="font-medium pb-1 pt-0  profile__position"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        <strong class="mr-1 font-bold">
                          {{ $t("message.gender") }}:
                        </strong>
                        <span>{{
                          staff.gender
                            ? $t("message." + staff.gender.name)
                            : null
                        }}</span>
                      </p>
                      <p
                        class="font-medium pb-1 pt-0  profile__position"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        <strong class="mr-1 font-bold"
                          >{{ $t("message.dateAdoption") }}:</strong
                        >
                        <span>{{ staff.hiring_date }}</span>
                      </p>
                      <p
                        class="font-medium pb-1 pt-0  profile__position"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        <strong class="mr-1 font-bold"
                          >{{ $t("message.salary") }}:</strong
                        >
                        <span>{{ Number(staff.salary).toLocaleString() }}</span>
                      </p>

                      <p
                        class="font-medium pb-1 pt-0  profile__position"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        <strong class="mr-1 font-bold"
                          >{{ $t("message.status") }}:</strong
                        >
                        <span class="my-warning">{{
                          staff.status
                            ? $t("message." + staff.status.name)
                            : null
                        }}</span>
                      </p>
                      <p
                        v-if="staff.staff_work_type"
                        class="font-medium pb-1 pt-0  profile__position"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        <strong class="mr-1 font-bold"
                          >{{ $t("message.staff_work_type") }}:</strong
                        >
                        <span class="my-warning">{{
                          staff.staff_work_type
                            ? $t("message." + staff.staff_work_type.name)
                            : null
                        }}</span>
                      </p>
                    </div>
                    <!-- </router-link> -->
                  </el-card>
                </div>
              </el-col>
            </el-row>
            <!-- end row  -->
            <div class="my___pagination mt-4">
              <crm-pagination
                @c-change="updatePagination"
                :class="mode ? 'pagination__day' : 'pagination__night'"
                :pagination="pagination"
              ></crm-pagination>
            </div>
          </el-tab-pane>

          <el-tab-pane name="second" v-loading="loadingData">
            <span slot="label"><i class="el-icon-s-grid"></i></span>
            <div class="card-table-header table-crm-smart p-0 custom__scroll">
              <table
                :class="mode ? 'table__myday' : 'table__mynight'"
                class="table-my-code table-bordered mini-th layout__initial"
              >
                <thead>
                  <tr>
                    <th class="w100p" v-if="columns.id.show">
                      {{ columns.id.title }}
                    </th>

                    <th v-if="columns.name.show">
                      {{ columns.name.title }}
                    </th>

                    <th v-if="columns.last_name.show">
                      {{ columns.last_name.title }}
                    </th>

                    <th v-if="columns.middle_name.show">
                      {{ columns.middle_name.title }}
                    </th>
                    <th v-if="columns.salary.show">
                      {{ columns.salary.title }}
                    </th>

                    <th v-if="columns.phone_number.show">
                      {{ columns.phone_number.title }}
                    </th>

                    <th v-if="columns.email.show">
                      {{ columns.email.title }}
                    </th>

                    <th v-if="columns.gender_id.show">
                      {{ columns.gender_id.title }}
                    </th>

                    <th v-if="columns.nation_id.show">
                      {{ columns.nation_id.title }}
                    </th>

                    <th v-if="columns.work_type_id.show">
                      {{ columns.work_type_id.title }}
                    </th>

                    <th v-if="columns.graphic_id.show">
                      {{ columns.graphic_id.title }}
                    </th>

                    <th v-if="columns.position_id.show">
                      {{ columns.position_id.title }}
                    </th>

                    <th v-if="columns.position_ids.show">
                      {{ columns.position_ids.title }}
                    </th>

                    <th v-if="columns.department_id.show">
                      {{ columns.department_id.title }}
                    </th>

                    <th v-if="columns.reason_id.show">
                      {{ columns.reason_id.title }}
                    </th>

                    <th v-if="columns.date_of_birth.show">
                      {{ columns.date_of_birth.title }}
                    </th>

                    <th v-if="columns.hiring_date.show">
                      {{ columns.hiring_date.title }}
                    </th>

                    <th v-if="columns.passport_number.show">
                      {{ columns.passport_number.title }}
                    </th>

                    <th v-if="columns.passport_issued_by.show">
                      {{ columns.passport_issued_by.title }}
                    </th>

                    <th v-if="columns.passport_valid_until.show">
                      {{ columns.passport_valid_until.title }}
                    </th>

                    <th v-if="columns.inn.show">
                      {{ columns.inn.title }}
                    </th>
                    <th v-if="columns.telegram.show">
                      {{ $t("message.telegram") }}
                    </th>
                    <th v-if="columns.telegram_staff.show">
                      {{ $t("message.telegram_staff") }}
                    </th>
                    <th v-if="columns.izn_password.show">
                      {{ columns.izn_password.title }}
                    </th>
                    <th v-if="columns.children.show">
                      {{ columns.children.title }}
                    </th>
                    <th v-if="columns.category.show">
                      {{ columns.category.title }}
                    </th>
                    <th v-if="columns.district_id.show">
                      {{ columns.district_id.title }}
                    </th>

                    <th v-if="columns.created_at.show">
                      {{ columns.created_at.title }}
                    </th>
                    <th v-if="columns.updated_at.show">
                      {{ columns.updated_at.title }}
                    </th>

                    <th v-if="columns.settings.show">
                      <i class="el-icon-setting"></i>
                    </th>
                  </tr>

                  <tr class="filter_sorche">
                    <th v-if="columns.id.show">
                      <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.id"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.id.title"
                        class="id_input"
                      ></el-input>
                    </th>

                    <th v-if="columns.name.show">
                      <crm-input
                        size="mini"
                        :placeholder="columns.name.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.name"
                      ></crm-input>
                    </th>

                    <th v-if="columns.last_name.show">
                      <crm-input
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.last_name.title"
                        v-model="filterForm.last_name"
                      ></crm-input>
                    </th>

                    <th v-if="columns.middle_name.show">
                      <crm-input
                        size="mini"
                        :placeholder="columns.middle_name.title"
                        v-model="filterForm.middle_name"
                        :class="mode ? 'filter__day' : 'filter__night'"
                      ></crm-input>
                    </th>

                    <th v-if="columns.salary.show"></th>

                    <th v-if="columns.phone_number.show">
                      <crm-input
                        size="mini"
                        :placeholder="columns.phone_number.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.phone_number"
                      ></crm-input>
                    </th>
                    <th v-if="columns.email.show">
                      <crm-input
                        :placeholder="columns.email.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.email"
                      ></crm-input>
                    </th>

                    <th v-if="columns.gender_id.show">
                      <select-gender
                        size="mini"
                        :placeholder="columns.gender_id.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.gender_id"
                      ></select-gender>
                    </th>

                    <th v-if="columns.nation_id.show">
                      <select-nation
                        size="mini"
                        :placeholder="columns.nation_id.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.nation_id"
                      ></select-nation>
                    </th>

                    <th v-if="columns.work_type_id.show">
                      <select-work-type
                        size="mini"
                        :table_name="'staffs'"
                        :placeholder="columns.work_type_id.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.work_type_id"
                      >
                      </select-work-type>
                    </th>

                    <th v-if="columns.graphic_id.show">
                      <select-graphic
                        size="mini"
                        :placeholder="columns.graphic_id.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.graphic_id"
                      >
                      </select-graphic>
                    </th>

                    <th v-if="columns.position_id.show">
                      <select-position
                        size="mini"
                        :placeholder="columns.position_id.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.position_id"
                      >
                      </select-position>
                    </th>

                    <th v-if="columns.position_ids.show">
                      <select-position
                        size="mini"
                        :placeholder="columns.position_ids.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.position_ids"
                      >
                      </select-position>
                    </th>

                    <th v-if="columns.department_id.show">
                      <select-department
                        size="mini"
                        :placeholder="columns.department_id.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.department_id"
                      >
                      </select-department>
                    </th>

                    <th v-if="columns.reason_id.show"></th>

                    <th v-if="columns.date_of_birth.show">
                      <crm-date-picker
                        size="mini"
                        :placeholder="columns.date_of_birth.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.date_of_birth"
                      ></crm-date-picker>
                    </th>
                    <th v-if="columns.hiring_date.show">
                      <crm-date-picker
                        size="mini"
                        :placeholder="columns.hiring_date.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.hiring_date"
                      ></crm-date-picker>
                    </th>
                    <th v-if="columns.passport_number.show">
                      <crm-input
                        size="mini"
                        :placeholder="columns.passport_number.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.passport_number"
                      ></crm-input>
                    </th>

                    <th v-if="columns.passport_issued_by.show">
                      <crm-input
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.passport_issued_by.title"
                        v-model="filterForm.passport_issued_by"
                      ></crm-input>
                    </th>
                    <th v-if="columns.passport_valid_until.show">
                      <crm-date-picker
                        size="mini"
                        :placeholder="columns.passport_valid_until.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.passport_valid_until"
                      ></crm-date-picker>
                    </th>
                    <th v-if="columns.inn.show">
                      <!-- <crm-input
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.inn.title"
                        v-model="filterForm.inn"
                      ></crm-input> -->
                      <crm-input
                        :placeholder="columns.inn.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.inn"
                        :maskFormat="'pinfl'"
                        :inputValue="filterForm.inn"
                        :size="'medium'"
                      ></crm-input>
                    </th>
                    <th v-if="columns.telegram.show">
                      <el-select
                        placeholder="Telegram"
                        class="d-block"
                        v-model="filterForm.telegram"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :multiple="multiple"
                        :size="'small'"
                        filterable
                        clearable
                      >
                        <el-option
                          :label="$t('message.yes')"
                          :value="true"
                        ></el-option>
                        <el-option
                          :label="$t('message.no')"
                          :value="false"
                        ></el-option>
                      </el-select>
                    </th>
                    <th v-if="columns.telegram_staff.show">
                      <el-select
                        placeholder="Telegram"
                        class="d-block"
                        v-model="filterForm.telegram_staff"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :multiple="multiple"
                        :size="'small'"
                        filterable
                        clearable
                      >
                        <el-option
                          :label="$t('message.yes')"
                          :value="true"
                        ></el-option>
                        <el-option
                          :label="$t('message.no')"
                          :value="false"
                        ></el-option>
                      </el-select>
                    </th>
                    <th v-if="columns.izn_password.show">
                      <crm-input
                        :placeholder="columns.izn_password.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.izn_password"
                      ></crm-input>
                    </th>
                    <th v-if="columns.children.show">
                      <el-select
                        placeholder="Дети"
                        class="d-block"
                        v-model="filterForm.children"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :size="'small'"
                        filterable
                        clearable
                      >
                        <el-option
                          :label="$t('message.yes')"
                          :value="true"
                        ></el-option>
                        <el-option
                          :label="$t('message.no')"
                          :value="false"
                        ></el-option>
                      </el-select>
                    </th>
                    <th v-if="columns.category.show">
                      <select-category
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.category.title"
                        v-model="filterForm.category_id"
                      >
                      </select-category>
                    </th>

                    <th v-if="columns.district_id.show">
                      <select-district
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.district_id.title"
                        v-model="filterForm.district_id"
                      >
                      </select-district>
                    </th>

                    <th v-if="columns.created_at.show">
                      <crm-date-picker
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.created_at.title"
                        v-model="filterForm.created_at"
                      ></crm-date-picker>
                    </th>

                    <th v-if="columns.updated_at.show">
                      <crm-date-picker
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.updated_at.title"
                        v-model="filterForm.updated_at"
                      ></crm-date-picker>
                    </th>

                    <th class="settinW" v-if="columns.settings.show"></th>
                  </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                  <tr
                    v-for="staff in list"
                    :key="staff.id"
                    class="cursor-pointer"
                    @dblclick="profile(staff)"
                  >
                    <td v-if="columns.id.show">{{ staff.id }}</td>

                    <td v-if="columns.name.show">
                      {{ staff.name }}
                    </td>

                    <td v-if="columns.last_name.show">
                      {{ staff.last_name }}
                    </td>
                    <td v-if="columns.middle_name.show">
                      {{ staff.middle_name }}
                    </td>
                    <td v-if="columns.salary.show">
                      {{ Number(staff.salary).toLocaleString() }}
                    </td>

                    <td v-if="columns.phone_number.show">
                      {{ staff.phone_number }}
                    </td>
                    <td v-if="columns.email.show">
                      {{ staff.email }}
                    </td>

                    <td v-if="columns.gender_id.show">
                      {{
                        staff.gender ? $t("message." + staff.gender.name) : null
                      }}
                    </td>

                    <td v-if="columns.nation_id.show">
                      {{ staff.nation ? staff.nation.nationality : null }}
                    </td>

                    <td v-if="columns.work_type_id.show">
                      {{
                        staff.work_type
                          ? $t("message." + staff.work_type.work_type_name)
                          : null
                      }}
                    </td>

                    <td v-if="columns.graphic_id.show">
                      {{ staff.graphic ? staff.graphic.name : null }}
                    </td>

                    <td v-if="columns.position_id.show">
                      {{ staff.position ? staff.position.name : null }}
                    </td>

                    <td v-if="columns.position_ids.show">
                      <div
                        v-for="(position, positionIndex) in staff.positions"
                        :key="'position' + positionIndex"
                      >
                        {{ position.name }}
                      </div>
                    </td>

                    <td v-if="columns.department_id.show">
                      {{ staff.department ? staff.department.name : null }}
                    </td>

                    <td v-if="columns.reason_id.show">
                      {{ staff.reason ? staff.reason.name : null }}
                    </td>
                    <td v-if="columns.date_of_birth.show">
                      {{ staff.date_of_birth }}
                    </td>

                    <td v-if="columns.hiring_date.show">
                      {{ staff.hiring_date }}
                    </td>
                    <td v-if="columns.passport_number.show">
                      {{ staff.passport_number }}
                    </td>
                    <td v-if="columns.passport_issued_by.show">
                      {{ staff.passport_issued_by }}
                    </td>
                    <td v-if="columns.passport_valid_until.show">
                      {{ staff.passport_valid_until }}
                    </td>
                    <td v-if="columns.inn.show">
                      {{ staff.inn }}
                    </td>
                    <td v-if="columns.telegram.show">
                      {{
                        staff.telegram_two
                          ? $t("message.yes")
                          : $t("message.no")
                      }}
                    </td>
                    <td v-if="columns.telegram_staff.show">
                      {{
                        staff.telegram_staff_two
                          ? $t("message.yes")
                          : $t("message.no")
                      }}
                    </td>
                    <td v-if="columns.izn_password.show">
                      {{ staff.izn_password }}
                    </td>
                    <td v-if="columns.children.show">
                      {{
                        staff.children_two
                          ? $t("message.yes")
                          : $t("message.no")
                      }}
                    </td>
                    <td v-if="columns.category.show">
                      {{ staff.category }}
                    </td>
                    <td v-if="columns.district_id.show">
                      {{ staff.district ? staff.district.name : null }}
                    </td>

                    <td v-if="columns.created_at.show">
                      {{ staff.created_at }}
                    </td>

                    <td v-if="columns.updated_at.show">
                      {{ staff.updated_at }}
                    </td>

                    <td v-if="columns.settings.show" class="settings-td">
                      <crm-settings
                        :name="'StaffController'"
                        :model="staff"
                        :actions="actions"
                        :permissionShow="'staffs.update'"
                        :permissionDestroy="'staffs.destroy'"
                        @edit="edit"
                        @delete="destroy"
                        @changeStatus="changeStatus"
                      ></crm-settings>
                    </td>
                  </tr>
                </transition-group>
              </table>
              <div class="my___pagination">
                <crm-pagination
                  @c-change="updatePagination"
                  :class="mode ? 'pagination__day' : 'pagination__night'"
                  :pagination="pagination"
                ></crm-pagination>
              </div>
            </div>
            <!-- end card-table-header -->
          </el-tab-pane>
        </el-tabs>

        <side-bar-department
          :class="{ classA: scrollPosition < 0, classB: scrollPosition > 0 }"
          ref="sideBarDepartment"
          :company_id="filterForm.company_id"
          :showSideBar="showSideBar"
          :is_current="true"
          @triggredChanges="triggeredDepartmentIds"
          @setDepartment="departmentSetter"
        >
        </side-bar-department>
      </div>
    </div>
    <div class="app-modal app-modal__full">
      <el-drawer
        :with-header="false"
        :visible.sync="showFilterDialog"
        size="30%"
        ref="drawerFilter"
      >
        <div>
          <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
              <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <div class="large--title m-0 d-flex align-center">
                  <span
                    class="mr-4"
                    :class="mode ? 'text__day2' : 'text__night2'"
                    >Форма фильтра</span
                  >
                </div>
                <div>
                  <el-button
                    type="warning"
                    size="medium"
                    @click="showFilterDialog = false"
                    plain
                    >{{ $t("message.close") }}
                  </el-button>
                </div>
              </div>
            </div>
            <!-- app-modal__header end -->
            <div class="app-modal__body  p-2 pl-3 pb-4" v-loading="loadingData">
              <div
                class="timeline-items__right rounded-sm w-100 p-4"
                :class="mode ? 'table__myday' : 'table__mynight'"
              >
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("message.company") }}
                      </span>
                      <select-company
                        :id="filterForm.company_id"
                        :class="mode ? 'input__day' : 'input__night'"
                        v-model="filterForm.company_id"
                      ></select-company>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("message.branch") }}
                      </span>
                      <select-branch
                        :company_id="filterForm.company_id"
                        :id="filterForm.branch_id"
                        :class="mode ? 'input__day' : 'input__night'"
                        v-model="filterForm.branch_id"
                      ></select-branch>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("message.gender") }}
                      </span>
                      <select-gender
                        :placeholder="$t('message.gender')"
                        :class="mode ? 'input__day' : 'input__night'"
                        v-model="filterForm.gender_id"
                      ></select-gender>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("message.manager") }}
                      </span>
                      <el-switch v-model="filterForm.is_manager"> </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("message.leader") }}
                      </span>
                      <el-switch v-model="filterForm.is_leader"> </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("message.late_to_works") }}
                      </span>
                      <el-switch v-model="filterForm.late_to_work"> </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <h4
                      class="input--label d-block mb-2"
                      :class="mode ? 'text__day2' : 'text__night2'"
                    >
                      {{ $t("Те, кто не приходит на работу") }}
                    </h4>
                    <hr />
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("В течение этого месяца") }}
                      </span>
                      <el-switch v-model="filterForm.last_mounth_dont_come">
                      </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("За последние 2 месяца") }}
                      </span>
                      <el-switch v-model="filterForm.last_two_mounth_dont_come">
                      </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <div class="app-form__group mb-4">
                      <span
                        class="input--label d-block mb-2"
                        :class="mode ? 'text__day2' : 'text__night2'"
                      >
                        {{ $t("Те, кто не одного дня не приходили") }}
                      </span>
                      <el-switch v-model="filterForm.never_come"> </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <hr />
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :wrapperClosable="false"
        :visible.sync="drawerCreate"
        size="95%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create
          :company_id="filterForm.company_id"
          :company_id_from_deparment="department.company_id"
          :branch_id="department.branch_id"
          :department_id="department.id"
          ref="drawerCreateChild"
          drawer="drawerCreate"
        ></crm-create>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :wrapperClosable="false"
        :visible.sync="drawerUpdate"
        size="95%"
        ref="drawerUpdate"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
      >
        <crm-update
          :selectedItem="selectedItem"
          ref="drawerUpdateChild"
          drawer="drawerUpdate"
        ></crm-update>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination";
import CrmCreate from "@/views/staff/components/crm-create";
import CrmUpdate from "@/views/staff/components/crm-update";
import SelectDepartment from "@/components/filters/inventory/select-department";
import SelectCompany from "@/components/filters/inventory/select-company";
import SelectReason from "@/components/filters/inventory/select-reason";
import SelectNation from "@/components/filters/inventory/select-nation";
import SelectGraphic from "@/components/filters/inventory/select-graphic";
import SelectDistrict from "@/components/filters/inventory/select-district";
import sideBarDepartment from "./components/side-bar-department";
import selectGender from "@/components/filters/inventory/select-gender";
import { mapGetters, mapActions, mapState } from "vuex";
import utils from "./utils/utils";
import { i18n } from "@/utils/i18n";
import SelectWorkType from "@/components/filters/inventory/select-work-type";
import SelectBranch from "../../components/filters/inventory/select-branch.vue";
export default {
  mixins: [utils],
  name: "StaffController",
  props: {
    size: {
      default: "small"
    },
    multiple: {
      type: Boolean
    }
  },
  data() {
    return {
      showFilterDialog: false
    };
  },
  components: {
    CrmCreate,
    CrmUpdate,
    SelectDepartment,
    Pagination,
    SelectNation,
    SelectGraphic,
    SelectDistrict,
    SelectCompany,
    sideBarDepartment,
    SelectReason,
    SelectWorkType,
    SelectBranch,
    selectGender
  },
  created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    this.debouncedFetchData = _.debounce(this.fetchData, 500);
  },
  mounted() {
    if (_.isFunction(this.controlExcelData)) {
      this.controlExcelData();
    }
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    filterForm: {
      handler: async function(newVal, oldVal) {
        await this.updatePagination({ key: "page", value: 1 });
        await this.updateFilter(newVal);
        this.debouncedFetchData();
      },
      deep: true,
      immediate: true
    },
    sort: {
      handler: async function(newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true
    },
    "pagination.page": {
      handler: async function(newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true
    },
    "pagination.per_page": {
      handler: async function(newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true
    },
    columns: {
      handler: function() {
        this.controlExcelData();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      excel_datas: "staff/excel_data",
      list: "staff/list",
      columns: "staff/columns",
      pagination: "staff/pagination",
      statues: "staff/statues",
      filter: "staff/filter",
      sort: "staff/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    ...mapActions({
      updateList: "staff/index",
      setPagination: "staff/setPagination",
      updateSort: "staff/updateSort",
      updateFilter: "staff/updateFilter",
      updateColumn: "staff/updateColumn",
      updatePagination: "staff/updatePagination",
      show: "staff/show",
      empty: "staff/empty",
      delete: "staff/destroy",
      refreshData: "staff/refreshData"
    }),
    triggeredDepartmentIds(departmentIds) {
      this.filterForm.department_ids = departmentIds;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    closeDrawer(drawer) {
      if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
        this.$refs[drawer].closeDrawer();
      }
    },
    drawerClosed(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].closed();
      }
      if (this.reloadList === true) {
        this.fetchData();
        this.afterFetchData();
      }
      if (_.isFunction(this.empty)) {
        this.empty();
      }
    },

    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened();
        }
      }
    },
    listChanged() {
      this.reloadList = true;
    },
    afterFetchData() {
      this.reloadList = false;
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (
            column.show &&
            column.column !== "settings" &&
            column.column !== "telegram" &&
            column.column !== "email" &&
            column.column !== "reason_id" &&
            column.column !== "position_ids"
          ) {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    fetchData() {
      let status = { status_code: "00" };
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        ...status
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then(res => {
            this.loadingData = false;
          })
          .catch(err => {
            this.loadingData = false;
          });
      }
    }
  }
};
</script>
<style lang="scss">
.completion_percentage {
  position: absolute;
  top: 7px;
  right: 10px;
  display: inline-flex;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 800;
}
.staff_card {
  position: relative;
}
.icon_manager {
  position: absolute;
  font-size: 22px;
  top: 7px;
  left: 10px;
  color: #018affcf;
}
.icon_leader {
  position: absolute;
  font-size: 28px;
  top: -1px;
  left: 5px;
  color: #f5cf16;
}
.filter_icon {
  font-weight: bold;
  display: inline-flex;
  font-size: 32px;
  color: #018affcf;
  cursor: pointer;
  margin-right: 7px;
  [class^="el-icon-"] {
    font-weight: 700 !important;
  }
}
.el-card__body {
  padding: 12px;
}
.workers-info {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 60%;
  p {
    margin: 5px 0 !important;
  }
}
.staff_card {
  .el-card__body {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    .img-user-worker {
      height: 40% !important;
      margin-bottom: 0 !important;
    }
  }
}
.performance__night {
  .el-switch__core {
    background: #323248;
    border-color: rgb(187, 187, 187);
    &::after {
      background: rgb(238, 238, 238);
    }
  }
}
</style>
